@use "@/styles/constants/devices.scss" as *;
@import "@/components/sharedStyles/SharedContent.module.scss";

.container {
  color: var(--suggestedSmartAcca-primaryFontColor);
  background-color: var(--suggestedSmartAcca-secondaryBackgroundColor);
  margin-bottom: 16px;
  overflow: hidden;

  @media only screen and (min-width: $tablet) {
    border-radius: 8px;
  }

  .cardListHeaderContainer {
    align-items: center;
    background-color: var(--suggestedSmartAcca-primaryBackgroundColor);
  }
}

.headerAndLogoContainer {
  display: flex;
  align-items: flex-end;

  h2 {
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
    color: var(--suggestedSmartAcca-primaryFontColor);
    margin: 0 4px 0 0;
  }
}

.arrowButtonsContainer {
  button {
    background: none;
    border: none;
    margin: 0 0 0 12px;
    padding: 0;
  }

  button:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @media only screen and (max-width: $tablet) {
    display: none;
  }
}

.smartAccaCardsContainer {
  background-color: var(--suggestedSmartAcca-secondaryBackgroundColor);
  display: flex;
  padding: 16px;
  gap: 8px;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.smartAccaCard {
  background-color: var(--suggestedSmartAcca-primaryBackgroundColor);
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid var(--suggestedSmartAcca-borderColor);
  border-radius: 8px;
  min-width: 315px;
  position: relative;

  h3 {
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    font-size: 0.625rem;
    color: var(--suggestedSmartAcca-secondaryFontColor);
    margin: 0;
  }

  button {
    color: var(--suggestedSmartAcca-buttonFontColor);
    font-size: 0.6rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 10px 26px;
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    gap: 5px;
    background-color: var(--suggestedSmartAcca-buttonBackgroundColor);

    &[aria-pressed="true"] {
      background-color: var(--suggestedSmartAcca-activeButtonBackgroundColor);
    }
  }
}

.selectionsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  row-gap: 14px;
  min-height: 290px;
}

.selection {
  display: grid;
  grid-template-columns: 38px 1fr;
  align-items: start;
  column-gap: 10px;
}

.selectionDataContainer {
  display: flex;
  flex-direction: column;

  .selectionDetails {
    font-size: 0.75rem;
    font-weight: bold;
    color: var(--suggestedSmartAcca-primaryFontColor);
    margin: 0;
  }

  .fixtureDetails {
    line-height: unset;
    font-size: 0.625rem;
    color: var(--suggestedSmartAcca-secondaryFontColor);
    margin: 0;
  }
}

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 0.625rem;
    color: var(--suggestedSmartAcca-secondaryFontColor);
    padding: 8px 8px 16px;
    text-align: center;
    margin: 0;
    line-height: 11.5px;
  }
}

.footerLink {
  color: var(--suggestedSmartAcca-primaryFontColor);
  font-size: 0.8rem;
  display: flex;
  align-content: center;
  text-decoration: none;
  margin-bottom: 12px;

  & span {
    font-size: 0.625rem;
    margin-right: 0.3rem;
  }
}
